:root {
  --font-sans-fontfamily: "Open Sans", Roboto, Arial, Helvetica, sans-serif;
  --obv-background-color: unset;
}

.obvModal,
.obvBlockLayout {
  font-family: var(--font-sans-fontfamily);
}

.obvBlockLayout {
  background: var(--obv-background-color);
  line-height: initial;
}

.obvBlockLayout a {
  text-decoration: none;
  color: inherit;
}

.obvProductCard .obvProductTitle {
  font-size: 1em;
}

.obvBlockLayout .amzCTA__buttton {
  padding: 5px 10px;
  color: white;
  background: black;
  display: inline-block;
}
