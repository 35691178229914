/**
 * theme-lib
 * mixins, etc.
 */

@use "sass:math";
@use "sass:string";

:root {
  --obvTransition-speed: 0.5s;
  --obvTransition-speed--hover: 0.3s;
}

$animation-name: null;

@mixin obvFadeIn($duration: 0.6s, $opacityTo: 1, $opacityFrom: 0.2) {
  $animation-name: string.unique-id() !global;
  -webkit-animation: obvFadeIn $duration;
  animation: obvFadeIn $duration;
  animation-name: $animation-name;

  @keyframes #{$animation-name} {
    0% {
      opacity: $opacityFrom;
    }
    100% {
      opacity: $opacityTo;
    }
  }
}

@mixin obvGrowIn($duration: 0.2s) {
  $animation-name: string.unique-id() !global;
  -webkit-animation: obvGrowIn $duration;
  animation: obvGrowIn $duration;
  animation-name: $animation-name;

  @keyframes #{$animation-name} {
    0% {
      opacity: 0.1;
      width: 0;
      height: 0;
    }
    80% {
      opacity: 0.2;
    }
    100% {
      opacity: 0.8;
    }
  }
}
