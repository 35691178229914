@use "./theme-lib.scss" as *;
@use "./amazon.scss" as *;

$menu-background: white;

:root {
  --gallery-max-width: 1200px;
}

.obvGallery {
  --line-height-reset: 1.3em;
  --font-size-reset: 16px;
  --font-sans-fontfamily: "Open Sans", Roboto, Arial, Helvetica, sans-serif;
  --section-indent: 1em;
  --section-spacing: 0.5em;
  --gallery-max-width: 1200px;
  --shop-default-background-color: var(--obv-background-color);
  --shop-link-color: cornflowerblue;
}

// separate app structure so .obvGallery can be used with Modal
.obvGalleryContainer {
  background: var(--shop-default-background-color);
  // min-height: 100vh;
}

.obvGallery {

  font-family: var(--font-sans-fontfamily);
  font-size: var(--font-size-reset);
  line-height: var(--line-height-reset);

  .obvBlockLayout {

    padding: 2em 0 0;

    &.obvMedia__size--sm {
      padding: 0 0.5em 1em;
    }

    &.obvMedia__size--xs {
      padding: 0 0 1em;
    }
  }

  .obvGallery__mainCardHeader {

    background: rgb(239, 239, 239);
    margin: 1em auto;
    align-items: center;

    padding: 1em 2em;

    &.obvMedia__size--xs {
      padding: 0 0.5em 1em;
    }

    h1 {
      font-size: 2em;
      font-weight: 500;
      margin: 0.5rem 0;
    }

    p {
      margin: 0.5rem 0;
    }
  }

  .obvGallery__billboard {
    margin: 0;

    p {
      margin: 20px 0 20px;
    }

    .ubtn-link {
      display: inline-block;
      margin-top: 20px;
    }
  }

  .obvGallery__billboard--block {
    box-sizing: border-box;
    padding: 3em 3em 2em;
    border: 1px solid #ccc;
    height: 100%;
  }

  &.obvMedia__size--xs .obvGallery__billboard--block {
    padding: 2em;
  }

  .obvGallery__lightWrapper {
    border: 1px solid #ccc;
  }

  .obvGallery__billboardCard {
    position: relative;

    h5 {
      margin: 0;
      font-size: 0.8em;
      font-weight: normal;
      text-align: center;
    }
  }

  .obvGallery__billboardCardLabel {
    padding: 0.5em 0 0em;
  }

  .obvProductCard {
    align-items: center;
    text-align: center;

    /* see box-shadow comment in .obvGalleryProduct__image */
    // padding: 4px 6px 6px;
  }

  .obvGalleryProduct__image {
    border: 0px solid lightgray;
    background-color: rgba(242, 240, 235, 1);

    &:not(.obvGalleryProduct__image--noBackground) {
      margin-bottom: 20px;
    }

    /* if box-shadow is used, adjust obvProductCard to have padding or the shadow will be clipped */
    // box-shadow: 0 4px 8px 1px rgb(0 0 0 / 0.4);
    // border-radius: 8px;
  }

  .obvProductTitle {
    font-size: 0.8em;
    text-transform: uppercase;
    padding: 0 0.5em;
  }

  .obvProduct__item--blocktype {
    font-size: 0.8em;
    // font-style: italic;
    padding: 0 0.5em;
    min-height: 3em;
  }

  .obvSection {
    max-width: var(--gallery-max-width);
    margin: 0 auto 3em;
  }

  .obvGallery__menu {
    background: $menu-background;
  }

  &.obvMedia__size--xs .obvGallery__menu {
    margin-bottom: 1em;
    padding-bottom: 0.5em;
    border-bottom: 1px solid grey;

    .obvGallery__menuItem {
      border-radius: 6px;
      background: #f0f0f0;
      padding: 5px 8px;
      margin: .25em .25em;
      border-bottom: none;
      opacity: 0.5;
    }

    .obvGallery__menuItem--selected {
      background: #bbb;
      opacity: 1;
    }
  }

  .obvSectionHeader {
    h4 {
      font-size: 1.1em;
      font-weight: 400;
    }
  }

  .obvGallery__altMenu {
    &.obvBlockLayout {
      margin-bottom: 1em;
    }
  }

  .obvGallery__altMenuItem {
    cursor: pointer;

    .obvProductTitle {
      text-align: center;
    }
  }

  .obvBillboard__link {
    cursor: pointer;
    color: var(--shop-link-color);
  }

  .obvGallery__menuWrapper {
    display: flex;
    flex-wrap: wrap;
    max-width: var(--gallery-max-width);
    margin: 0 auto;
  }

  .obvGallery__menuItem {
    font-size: 0.9em;
    color: black;
    margin: .5em .5em .5em 0;
    cursor: pointer;
    padding: 0 5px 3px;
    border-bottom: 3px solid $menu-background;

    transition: border-bottom 0.4s;

    &:not(.obvGallery__menuItem--selected):hover {
      border-bottom-color: #aaa;
    }
  }

  .obvGallery__menuItem--selected {
    border-bottom-color: #888;
  }

  // editor aware - remove margins so looks like editing an app
  .obvEditor__rootpane .obvEditor__devicepane--desktop .obvEditor__root {
    max-width: inherit;
    padding: 0;
    margin: 0;
  }

  .obvNavButton {
    top: 45%;
  }

  .obvInfoButton {

    cursor: pointer;
    color: var(--shop-link-color);

  }

  .obvGalleryOverlay__button {

    box-sizing: border-box;
    min-width: 6em;

    margin: 0.5em !important; // <-- obviyo wordpress :last-child problem
    padding: 0.5em 0.5em;

    font-size: 0.8em;
    text-align: center;
    text-decoration: none;

    border: 1px solid #aaa;
    background: transparent;
    color: #f8f8f8;

    cursor: pointer;

    &:hover {
      background: rgba(0, 0, 0, 1);
    }
  }

  .obvGalleryProductCard__cta {

    display: inline-flex;

    margin-top: 5px;
    padding: 0.6em 3em;

    font-size: 0.875em;
    text-align: center;

    border: none;
    background: black;
    color: #f8f8f8;

    cursor: pointer;

    &:hover {
      background: rgb(150, 191, 72);
      color: white;
    }
  }

}

.obv-fade-in {
  @include obvFadeIn
}


.obvUtil__absoluteCenter {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.obvUtil__absolute--topRight {
  position: absolute;
  top: 0;
  right: 0;
}

.obvUtil__absolute--bottomRight {
  position: absolute;
  bottom: 0;
  right: 0;
}

.obvGallery__Sub4 {
  .obvProductTitle {
    margin-top: 0;
    text-transform: initial;
    font-size: 0.7em;
  }
}

.obvModal__dialog {
  max-width: var(--gallery-max-width);
}

.obvGallery__title {
  font-weight: bold;
}

.obvGallery__innerContent {
  font-size: 0.875em;
  margin-top: 1em;
  margin-bottom: 1em;
}

a.obvGallery_cta {

  display: inline-block;
  margin-top: 0.5em;
  font-weight: bold;
  padding: 15px 30px;
  text-align: center;
  border-radius: 8px;

  background: black;
  color: white;
}

.obvModal {

  .obvModal__content {
    padding: 2em 2em 2em;
  }

  .obvSection__header {
    max-width: 500px;
    margin-bottom: 3em;
  }

  z-index: 10000;
}

.obvGallery__infoModal {
  max-width: 800px;

  .obvGallery__innerContent {
    font-size: 0.875em;
    line-height: normal;

    >* {
      margin-top: 6px;
      margin-bottom: 6px;
      clear: unset;
    }

    >table {
      margin-top: 1em;
    }

    ul {
      list-style: unset;

      li {
        list-style: unset;
        margin-left: 2em;
        margin-bottom: 6px;
      }
    }
  }
}

.obvGallery__MainCardSubMenu {
  margin-top: 2em;
}

.obvGallery__MainCardSubMenuItem {
  display: inline-flex;
  padding: 5px 10px;
  margin-right: 8px;
  margin-bottom: 8px;
  border-radius: 8px;
  border: 1px solid #888;
  cursor: pointer;
}

.obvGallery__GalleryBestPracticesSlider {
  margin-top: 2em;
  margin-bottom: 2em;
}

.obvGallery__GalleryBestPracticesSliderCard {
  border: 1px solid #ddd;
  padding: 2em;
  height: 100%;
  box-sizing: border-box;

  h5 {
    margin: 0;
    font-size: 0.9em;
    font-weight: bold;
    line-height: initial;
  }

  p {
    margin: 10px 0 10px;
    font-size: 0.9em;
    line-height: initial;
  }
}

.obvGallerySnippet__singleBlock {
  height: 100%;
  width: 100%;
}